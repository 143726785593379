.page-header[data-v-70a63a62] {
  margin-top: 0;
}
.page-header.pictograms[data-v-70a63a62] {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 0;
}
.page-header.pictograms > a[data-v-70a63a62] {
  white-space: break-spaces;
}
.alert-padding[data-v-70a63a62] {
  margin-bottom: 0;
  margin-top: 15px;
}
